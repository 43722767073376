var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 mt-2 table-card px-1"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-center",staticStyle:{"gap":"10px"}},_vm._l((_vm.allNumbers),function(num,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.generateShowOrNow(_vm.tableData, num)),expression:"generateShowOrNow(tableData, num)"}],key:index,staticClass:"numbers px-2",class:[
                !num.isOpen ||
                parseInt(num.current_amount, 10) >
                    parseInt(num.limit_amount, 10) ||
                parseInt(num.current_amount, 10) ===
                    parseInt(num.limit_amount, 10)
                    ? 'disable'
                    : '',
                num.isSelect ? 'active' : '',
            ],staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.selectNumbers(num, index)}}},[(_vm.isTwod)?_c('div',{staticStyle:{"position":"absolute","margin-top":"-1.3rem","right":"0","margin-right":"-0.3rem"}},[_c('div',{staticClass:"badge badge-info",class:_vm.badgeClassGenerator(num.odds)},[_vm._v(" "+_vm._s(num.odds)+" ")])]):_vm._e(),_c('div',[_c('div',{staticClass:"font-weight-800"},[_vm._v(" "+_vm._s(num.number)+" ")]),(num.isOpen ||
                  parseInt(num.current_amount, 10) <
                      parseInt(num.limit_amount, 10) ||
                  parseInt(num.current_amount, 10) !==
                      parseInt(num.limit_amount, 10))?_c('b-progress',{attrs:{"value":parseInt(num.current_amount),"max":parseInt(num.limit_amount),"variant":_vm.variantGenerate(num)}}):_vm._e()],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }