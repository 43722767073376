<template>
    <div>
        <!-- Footer -->
        <footer class="footer mt-3" v-if="$route.path !== '/maung-fb' && $route.path !== '/body-fb'">
            <!-- <img src="/build/assets/img/brand/khit_thit.jpg" alt="" /> -->
            <div class="align-items-center justify-content-lg-between">
                <div class="col-lg-12 text-center">
                    <ul class="nav nav-footer justify-content-between">
                        <li
                            class="nav-item"
                            @click="$router.push('/home')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <!-- <img class="mx-auto" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/home%202.png" style="width: auto; height: 25px" alt="" v-if="$route.path === '/home'"> -->
                                <img class="mx-auto" src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/08c5dfef-af1c-44d4-245d-ede6ff14d900/public" style="width: auto; height: 30px" alt="">
                                <div class="mx-auto pt-1 text-sm footer-icon-text" :class="$route.path === '/home' ? 'active' : ''" style="font-weight: 600 !important;">
                                    {{$t('footer.home')}}
                                </div>
                            </div>
                        </li>
                        <!-- <li
                            class="nav-item"
                            @click="openModal()"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <img class="mx-auto" src="/build/assets/img/icons/Download.png" style="width: 18px; height: 18px" alt="">
                                <div class="mx-auto pt-1 text-sm" style="color: #a486ea; font-weight: 500 !important;">
                                    {{
                                        $store.state.language === "en"
                                            ? "Download"
                                            : "ဒေါင်းလုတ်"
                                    }}
                                </div>
                            </div>
                        </li> -->
                        <!-- :class="linkIsActive('/deposit') ? 'active' : ''" -->
                        <li
                            class="nav-item"
                            @click="$router.push('/deposit')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <!-- <img class="mx-auto" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/Deposite%202.png" style="width: auto; height: 25px" alt="" v-if="$route.path === '/deposit'"> -->
                                <img class="mx-auto" src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/dcd5b086-72fb-4f38-9001-198f0a095700/public" style="width: auto; height: 30px" alt="">
                                <div class="mx-auto text-sm footer-icon-text" :class="$route.path === '/deposit' ? 'active' : ''" style="font-weight: 600 !important;">
                                    {{$t('footer.deposit')}}
                                </div>
                            </div>
                        </li>
                        <li
                            class="nav-item"
                            @click="$router.push('/withdraw')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <img class="mx-auto" src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/56c55dde-2511-45a4-a608-ee9b79855300/public" style="width: auto; height: 30px" alt="">
                                <!-- <img class="mx-auto" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/withdraw%201.png" style="width: auto; height: 25px" alt="" v-if="$route.path !== '/withdraw'"> -->

                                <div class="mx-auto text-sm footer-icon-text" :class="$route.path === '/withdraw' ? 'active' : ''" style="font-weight: 600 !important;">
                                    {{$t('footer.withdraw')}}
                                </div>
                            </div>
                        </li>
                        <li
                            class="nav-item"
                            @click="$router.push('/betslip-history')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <!-- <img class="mx-auto" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/promotion%202.png" style="width: auto; height: 25px" alt="" v-if="$route.path === '/promotion'"> -->
                                <img class="mx-auto pulse" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/Screenshot_2024-10-29_at_10.53.3-removebg-preview.png" style="width: auto; height: 30px" alt="">
                                <div class="mx-auto pt-1 text-sm footer-icon-text" :class="$route.path === '/betslip-history' ? 'active' : ''" style="color: black; font-weight: 600 !important;">
                                    {{$t('setting.voucher')}}
                                </div>
                            </div>
                        </li>
                        <li
                            class="nav-item"
                            @click="$router.push('/setting')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <img class="mx-auto" src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/1a53ec56-725f-4ed2-cd87-ef4759eac800/public" style="width: auto; height: 30px" alt="">
                                <!-- <img class="mx-auto" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/yyr_cards/icons/profile%201.png" style="width: auto; height: 25px" alt="" v-if="$route.path !== '/setting'"> -->
                                <div class="mx-auto pt-1 text-sm footer-icon-text" :class="$route.path === '/setting' ? 'active' : ''" style="font-weight: 600 !important;">
                                    {{$t('footer.profile')}}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>

        <!-- === only amount === -->
        <div class="col-4">
                <div
                    class="modal fade"
                    id="download-modal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal- modal-dialog-centered"
                        role="document"
                    >
                        <div class="modal-content">
                            <div
                                class="text-center modal-cross"
                                @click="closeModal"
                                style="font-size: 1rem; font-weight: 600;"
                            >
                                X
                            </div>
                            <div class="modal-body d-flex justify-content-center pb-4 pt-2">
                                <div class="col-5 d-flex justify-content-center">
                                    <img
                                        src="/build/assets/img/icons/Android.png"
                                        alt=""
                                        style="width: 100%; height: auto;"
                                    />
                                </div>
                                <div class="col-5 d-flex justify-content-center">
                                    <img
                                        src="/build/assets/img/icons/IOS.png"
                                        alt=""
                                        style="width: 100%; height: auto;"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
</template>
<script>
import { Toast, Dialog } from 'vant';

export default {
  data() {
    return {
      is_live: false,
    };
  },
  methods: {
    closeModal() {
      $('#download-modal').modal('hide');
    },
    openModal() {
      $('#download-modal').modal('show');
    },
    /* example link = '/settings' */
    linkIsActive(link) {
      console.log(this.$route.path === link);
      if (this.$route.path === link) return true;
      return false;
    },
    async fetchFootballLive() {
      try {
        const res = await axios.get('/football-lives');
        this.is_live = res.data.data[0].is_live;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchFootballLive();
  },
};
</script>
<style scoped>
.pulse {
    -webkit-animation-name: pulse;
animation-name: pulse;
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
-webkit-animation-iteration-count: infinite;
animation-iteration-count: infinite;
  }
  @-webkit-keyframes pulse {
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  50% {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  }
  @keyframes pulse {
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  50% {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  } 

.footer-icon-text {
    color: #ffffff !important;
}
.footer-icon-text.active {
    color: #000000 !important;
}
.modal-content {
    border: 2px solid #714DC7;
}
td .fa-trash {
    color: red;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: #714DC7;
}
.banking-icons {
    width: auto;
    height: auto;
    padding: 5px;
    border-radius: 5px;
}
.bet-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    width: 50px;
    /* right: 0; */
    left: 5px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25em;
    color: white;
    z-index: 100;
    visibility: visible;
    opacity: 1;
}
.nav-item {
    /* margin-left: 10px;
    margin-right: 10px; */
    /* border: 1px solid red; */
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
    color: #02133E;
    min-width: 60px;
    /* height: 100px; */
}
/* .nav-item:hover,
.nav-item.active {
    border-radius: 5px;
    background: #02133E;
    color: red !important;
} */
@media (max-width: 767.98px) {
    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        /* background: white;#F5C0C2 */
        /* background: linear-gradient(180deg, #2d9707 0%, #06c112 100%); */
        background: linear-gradient(to top, #00b324, #094b02);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        box-shadow: 0px -2px 10px rgba(0, 0, 0, .5);
        border-radius: 32.5px 32.5px 0px 0px;
    }
    .banking-icons {
        width: auto;
        height: auto;
        padding: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 3px;
    }
    .footer {
        padding: 1px !important;
    }
    /* .banking-icons img {
        width: 25px;
        height: 25px;
        border-radius: 3px;
    } */
}
</style>
