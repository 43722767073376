<template>
  <div>
      <iframe name="iframe1" :srcdoc="isHttp ? null : $store.state.iframeURL" :src="isHttp ? $store.state.iframeURL : null" class="iframe-style"></iframe>
  </div>
</template>
<script>

export default {
async mounted() {
  if (!this.$store.state.iframeURL) {
    this.$router.push('/home');
  }
},
computed: {
  isHttp() {
    return this.$store.state.iframeURL.startsWith('http');
  }
}
};
</script>
<style scoped>
.iframe-style {
  width: 100vh; 
  height: 100vh; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 10000;
  background: #04090A !important;
}
</style>
