<template>
	<div style="background: #272B32">
		<!-- <div> -->
			<iframe src="https://statsinfo.co/live/" frameborder="0" width="100%" style="height: 93vh"></iframe>
		<!-- </div> -->
	</div>
</template>

<script>
import { Loading } from 'vant';
import { mapActions } from 'vuex';
	export default {
		components: { Loading },
		name: "Live",
		data: () => ({
			itemsCart: [],
			tab: "Live",
			liveMatches: [],
			pendingMatches: [],
		}),
		methods: {
			...mapActions(['fetchUser']),
			openTab(tabName) {
				this.tab = tabName;
			},
			async translateText(text, targetLanguage) {
				try {
					const translate = require("google-translate-api");

					const { text: translatedText } = await translate(text, {
						to: targetLanguage,
					});
					console.log(`Original: ${text}`);
					console.log(`Translation: ${translatedText}`);
				} catch (error) {
					console.error("Error translating text:", error);
				}
			},
			async redirectRoute(path, query) {
				try {
					console.log("query", query);
					// if (path === 'tip') {
					//   const text = this.$store.state.language === 'en'
					//     ? 'This feature is not support yet.'
					//     : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
					//   Dialog.alert({
					//     message: text,
					//     confirmButtonText:
					//                     this.$store.state.language === 'en'
					//                       ? 'Okay'
					//                       : 'Okay',
					//   }).then(() => {
					//     // on close
					//   });
					// } else {
					this.$router.push({ path, query });
					// }
				} catch (error) {
					console.log(error);
				}
			},
			async fetchMatches() {
				try {
					const res = await axios.get("https://api.myanmarsoccer.xyz/api/football-live-matches");
					// add matches to liveMatches when match_status is live
					this.liveMatches = res?.data?.data?.filter(
						(match) => match.match_status?.toLowerCase() === "live"
					);
					this.pendingMatches = res?.data?.data?.filter(
						(match) => match.match_status.toLowerCase() !== "live"
					);
					// this.itemsCart = res.data.data;
				} catch (error) {
					console.log(error);
				}
			},
		},
		watch: {
			tab() {
				if (this.tab === "Live") {
				}
			},
		},
		async mounted() {
			// const authUser = await this.fetchUser();
			// if (!authUser) {
			// 	this.$router.push('/auth/login');
			// }
			// this.fetchMatches();
		},
	};
</script>

<style scoped>
	.card {
		cursor: pointer;
		width: 100%;
		/* height: 170px; */
		border-radius: 10px !important;
	}

	img {
		width: 70px;
		height: 70px;
	}

	#pTag {
		width: 60px;
		height: 30px;
		background: #303030;
		font-weight: bolder;
		border-radius: 20px;
		text-align: center;
	}

	.layer {
		opacity: 0.9;
		width: 100%;
		height: 0;
		background: linear-gradient(#d7d2c9, #222222);
		border-radius: 10px;
		position: absolute;
		left: 0;
		bottom: 0;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 40px;
		text-align: center;
		font-size: 14px;
		transition: height 0.5s;
	}

	.work:hover img {
		transform: scale(1.1);
	}

	.work:hover .layer {
		height: 100%;
	}

	.live {
		animation-name: live-number-animation;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	@keyframes live-number-animation {
		from {
			background-color: red;
			color: white;
		}

		to {
			background-color: #fdd1da;
			color: red;
		}
	}

	.coming {
		animation-name: coming-number-animation;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	@keyframes coming-number-animation {
		from {
			background-color: #303030;
			color: white;
		}

		to {
			background-color: #9e9e9e;
			color: #fcfcfc;
		}
	}

	/* NEw */
	.tabs {
		display: flex;
		background-color: #ffffff;
		border-radius: 10px;
		overflow: hidden;
		width: 300px;
		padding: 4px;
		gap: 4px;
	}

	.tab-button {
		flex-grow: 1;
		background-color: #ffffff;
		border: none;
		border-radius: 10px;
		outline: none;
		cursor: pointer;
		padding: 10px 12px;
		transition: background-color 0.3s ease;
		font-size: 17px;
	}

	.tab-button:hover,
	.tab-button.active {
		background: linear-gradient(to right, #303030, #303030);
		color: white;
	}
</style>
