<template>
    <div style="background-attachment: fixed;height: 100vh; overflow-y: scroll; padding-bottom: 8rem; background-size: cover;">
        <NoticeBar
            class="col-lg-12 col-12 mx-auto notice-bar"
            scrollable
            :text="text"
            v-if="text"
        />
        <Sticky class="normal-box pt-3 px-2">
          <div style="background-color: rgb(63, 175, 74); box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; border-radius: 10px;" class="col-12 mx-auto py-3">
              <div class="d-flex justify-content-between">
                  <div class="col-4 p-0">
                      <button class="btn btn-danger" style="width: 100%; border-radius: 7px;" @click="$playSound(); showGroupBox()">
                          {{ $t('lottery.quick_bet') }}
                      </button>
                  </div>
                  <div class="col-3 p-0">
                    <button class="btn btn-dark mx-3" style="background: #000000; border-radius: 7px;" @click="$router.push('/user/dream-books?type=3d')">
                    {{$t('lottery.dream')}}
                    </button>
                  </div>
                  <div class="my-auto">
                    <i class="fas fa-clock" style="color: #000000"></i>  <b class="text-sm text-dark">{{ $t('lottery.bet_close') }} : </b> <span class="text-sm text-dark">{{ closeFormattedDate(threed.draw_date) }}, {{ threed.latest_bet }} PM</span>
                  </div>
              </div>
              <hr class="mt-2 mb-3">
              <div class="d-flex justify-content-between">
                  
                  <!-- <div class="col-3 p-0">
                    <button class="btn btn-dark mx-3" @click="$router.push('/user/dream-books?type=3d')">
                    {{
                        $store.state.language === "en"
                            ? "Dream"
                            : "အိမ်မက်"
                    }}
                    </button>
                  </div> -->
                  <div class="col-6 p-0">
                      <!-- {{ $store.state.language === "en" ? "Amount" : "ငွေပမာဏ" }} -->
                      <div class="form-group mb-0">
                              <input
                                  type="number"
                                  v-model="numberAmount"
                                  class="form-control amount"
                                  placeholder="100 MMK"
                                  style="height: 35px; border-radius: 8px; border: none"
                              />
                      </div>
                  </div>

                  <div class="d-flex">
                        <span class="px-2 my-auto py-1 number-reverse" :class="isReverse ? 'active' : ''" @click="isReverse = !isReverse">R</span>
                  </div>

                  <div class="col-4 p-0 pl-2">
                      <button class="btn btn-success" style="background: #000000; border: none; width: 100%; height: 35px; border-radius: 8px;"  @click="preBet">
                          <div>
                              {{$t('lottery.bet')}}
                          </div>
                      </button>
                  </div>

              </div>

              <div class="d-flex justify-content-between pt-3">
                  <div class="col-6 d-flex justify-content-start px-0">
                        <span class="text-dark text-sm font-weight-400 mr-2" @click="openColorsModal()">{{ $t('lottery.meaning_color') }}</span>
                        <span class="badge badge-dark text-white"  style="background: #000000"><i class="fa fa-info" @click="openColorsModal()"></i> </span>
                  </div>
                  <div class="col-6 d-flex justify-content-end px-0">
                    <i class="fa fa-redo my-auto text-lg"  style="color: #000000" @click="removeHistory()"></i>
                    <span class="my-auto text-dark text-sm font-weight-400 ml-2" @click="removeHistory()">{{ $t('lottery.all_clear') }}</span>
                  </div>
              </div>
          </div>
        </Sticky>
            

    <!-- <div class="mt-3 mx-auto" style="width: 85%; gap: 8px; display: grid; grid-template-columns: 1fr 1fr 1fr;">
        <div class="number-paginate px-4 mx-1" :class="tableData === 1 ? 'active' : ''" @click="tableData = 1">000 - 099</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 2 ? 'active' : ''" @click="tableData = 2">100 - 199</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 3 ? 'active' : ''" @click="tableData = 3">200 - 299</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 4 ? 'active' : ''" @click="tableData = 4">300 - 399</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 5 ? 'active' : ''" @click="tableData = 5">400 - 499</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 6 ? 'active' : ''" @click="tableData = 6">500 - 599</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 7 ? 'active' : ''" @click="tableData = 7">600 - 699</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 8 ? 'active' : ''" @click="tableData = 8">700 - 799</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 9 ? 'active' : ''" @click="tableData = 9">800 - 899</div>
        <div class="number-paginate px-4 mx-1" :class="tableData === 10 ? 'active' : ''" @click="tableData = 10">900 - 999</div>
    </div> -->
    

        <div class="d-flex p-0 flex-wrap main-wrapper mb-2" style="margin-top: -1rem; margin-bottom: 4rem;">
            <div class="col-lg-4 col-md-6 px-2 boxes mb-5 pb-5">
                <Table @numbers="getNumbers" :isTwod="false" :allNumbers="allNumbers" :tableData="tableData" :on-my-event="myEventSource"/>

            </div>
            <!-- <div class="col-lg-4 col-md-6 col-12 px-2 boxes mb-0">
              <NormalBox @numbers="getNumbers" type="threed" :allNumbers="$store.state.threed_numbers"/>
            </div> -->
            <div class="col-lg-4 col-md-6 px-2 boxes pb-5" style="margin-bottom: 5rem !important">
                <ThreedQuickGroupBox @numbers="getNumbers" @quickNumbers="getQuickNumbers"  @quickGroupNumbers="getNumbers"/>
            </div>
            <div class="col-lg-4 col-md-6 px-2">
                <div class="row">
                    <!-- === only amount === -->
                    <div class="col-md-4 cart-voucher">
                        <div
                            class="modal fade"
                            id="mobile-voucher"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="modal-form"
                            aria-hidden="true"
                        >
                            <div
                                class="
                                    modal-dialog
                                    modal-
                                    modal-dialog-centered
                                    modal-sm
                                "
                                role="document"
                            >
                                <div class="modal-content">
                                    <div
                                        class="text-center modal-cross"
                                        @click="closeModal"
                                    >
                                        X
                                    </div>
                                    <div class="modal-body p-0">
                                      <PreviewVoucher
                                          :datas="datas"
                                          @remove="removeHistory"
                                          class="preview-box"
                                          :isTwod="false"
                                          type="normal"
                                          v-if="!isMobile"
                                      />

                                        <PreviewVoucher
                                            :datas="datas"
                                            @remove="removeHistory"
                                            :isTwod="false"
                                            v-if="isMobile"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-lg-4 col-md-6 px-2">
            <div class="row">
                <!-- === only amount === -->
                <div class="col-md-4 cart-voucher">
                    <div
                        class="modal fade"
                        id="colors-modal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="modal-form"
                        aria-hidden="true"
                    >
                        <div
                            class="
                                modal-dialog
                                modal-
                                modal-dialog-centered
                                modal-sm
                            "
                            role="document"
                        >
                            <div class="modal-content">
                                <div class="modal-heading bg-danger py-3" style="border-top-right-radius:0.4375rem; border-top-left-radius:0.4375rem">
                                      <h4 class="text-center text-white">
                                        အရောင် အဓိပ္ပါယ် <br> 100% ဖြစ်သောအခါ ထီနံပါတ်များကို ပိတ်ပါမည်။
                                      </h4>
                                    </div>
                                <div class="modal-body px-5 d-flex py-3">
                                    <ul>
                                      <li class="my-2"> 
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: green; width:20px; height: 20px; border-radius: 50%;"></div> 50% အောက်
                                        </div>
                                      </li>
                                      <li class="my-2"> 
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: yellow; width:20px; height: 20px; border-radius: 50%;"></div> 50% မှ 75% အတွင်း 
                                        </div>
                                      </li>
                                      <li class="my-2">
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: red; width:20px; height: 20px; border-radius: 50%;"></div> 90% အထက် 
                                        </div>
                                      </li>
                                      <li class="my-2">
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: #7c7676; width:20px; height: 20px; border-radius: 50%;"></div> အပြည့် (100%)
                                        </div>
                                      </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import { NoticeBar, Sticky, Dialog } from 'vant';
import PreviewVoucher from '../../components/PreviewVoucher.vue';
import Table from '../../components/Table.vue';
import ThreedQuickGroupBox from '../../components/ThreedQuickGroupBox.vue';
import NormalBox from '../../components/NormalBox.vue';
import { newEventSource } from 'vue-parent-emit';
import { mapActions } from 'vuex';

export default {
  components: {
    PreviewVoucher,
    ThreedQuickGroupBox,
    NoticeBar,
    Table,
    NormalBox,
    Sticky,
  },
  data() {
    return {
      text: '',
      myEventSource: newEventSource(),
      datas: [],
      selected_data: {},
      isMobile: false,
      threed: {},
      numberAmount: null,
      isReverse: false,
      tableData: 1,
      allNumbers: [],
      voucherLength: 0
    };
  },
  methods: {
    getQuickNumbers(value){
      this.datas = value;
      $("#mobile-voucher").modal("show");
    },

    showGroupBox(){
      $('#3d_group_box').modal('show');
    },
    ...mapActions(['fetchUser']),
    openColorsModal() {
      $('#colors-modal').modal('show');
    },
    sendEvent() {
    this.myEventSource.emit();
  },
  slipId() {
      return this.voucherLength;
    },
  async checkCurrentVouchers() {
      try {
        const res = await axios.post('/vouchers/check', {
          type: 'threed',
        },{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          });
        this.voucherLength = res.data.voucherLength;
      } catch (error) {
        console.log(error);
      }
    },
    dialog(text) {
            Dialog.alert({
                message: text,
                confirmButtonText:
                    this.$store.state.language === "en"
                        ? "Go Back"
                        : "နောက်သို့ပြန်သွားမည်။",
            }).then(() => {
                // on close
            });
        },
    getPermutations(str) {
      if (str.length <= 1) return [str];
      
      const permutations = [];
      
      for (let i = 0; i < str.length; i++) {
        const char = str[i];
        const remainingChars = str.slice(0, i) + str.slice(i + 1);
        for (const permutation of this.getPermutations(remainingChars)) {
          permutations.push(char + permutation);
        }
      }

      return permutations;
    },
    async preBet() {
    try {
      if (this.isBet) {
          return false;
      }
      if (this.voucherId === this.slipId()) {
          return false;
      }
      if (this.datas.length == 0) {
          const dataError = this.$store.state.language === "en"
                            ? "Please pick some numbers!"
                            : "နံပါတ်အချို့ ရွေးပေးပါ။";
          this.dialog(dataError);
          return false;
      }
      if (!this.numberAmount) {
          const dataError = this.$store.state.language === "en" ? 'Please enter amount' : 'ငွေပမာဏထည့်ပေးပါ။';
          this.dialog(dataError);
          return false;
      }
      if (this.numberAmount < 100) {
          this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter at least 100 MMK'
          : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';

          const dataError = this.$store.state.language === "en" ? 'Please enter at least 100 MMK' : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';
          this.dialog(dataError);
          return false;
      }
      
      if(this.isReverse){
        // Step 1: Generate permutations for numbers in selected data (datas)
        const allPermutations = this.datas.flatMap(item => {
          const numberStr = String(item.number).padStart(3, '0'); // Pad with leading zeros
          return this.getPermutations(numberStr).map(perm => ({
            ...item,
            number: perm // Use permutation as the new number
          }));
        });

        // Step 2: Filter allNumbers based on permutations
        const filteredFromAllNumbers = this.allNumbers.filter(item =>
          allPermutations.some(permItem => permItem.number === String(item.number).padStart(3, '0'))
        );

        // Step 3: Merge filtered data with selected data (datas)
        const combinedData = [
          ...this.datas,
          ...filteredFromAllNumbers
        ];

        // Step 4: Remove duplicates based on number
        this.datas = Array.from(new Map(combinedData.map(item => [item.number, item])).values());

        // Ensure numbers are handled as strings with consistent padding and ordering
        this.datas.forEach(item => {
          item.number = String(item.number).padStart(3, '0');
        });
      }

      console.log(this.datas)

      this.datas = this.datas.map(item => ({
            ...item,          // Spread the existing properties of the object
            solo_amount: this.numberAmount,  // Add or update the `solo_amount` property
            draw: this.threed.draw_date
        }));
     
    $("#mobile-voucher").modal("show");
    } catch (error) {
        console.log(error);
    }
  },

    closeFormattedDate(draw) {
      return moment(draw).format('MMM Do');
    },

    closeModal() {
      $('#mobile-voucher').modal('hide');
    },
    getNumbers(value) {
      for (let i = 0; i < this.allNumbers.length; i += 1) {
        if(value.findIndex(obj => obj.number === this.allNumbers[i].number)  >= 0){
            this.allNumbers[i].isSelect = true;
        } else {
            this.allNumbers[i].isSelect = false;
        }
    }
      this.datas = value;
      this.datas = this.datas.map(item => ({
            ...item,          // Spread the existing properties of the object
            solo_amount: this.numberAmount, // Add or update the `solo_amount` property
            draw: this.threed.draw_date
        }));
    },
    async fetchThreedNumbers() {
      try {
        const res = await axios.post('/threed_numbers', {
          limit: 'all',
        },
        {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        });
       this.allNumbers = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async threeData() {
      try {
        const res = await axios.get('3d/data',
        {
          headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          }
        );
        this.threed = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async removeHistory() {
      this.datas = [];
      $("#mobile-voucher").modal("hide");
      this.numberAmount = null;
      this.sendEvent();
      this.allNumbers = this.allNumbers.map(item => ({
        ...item,
        isSelect: false
      }));
      await this.fetchClosedNumbers();
      await this.fetchThreedNumbers();
    },
    async fetchClosedNumbers() {
      try {
        const res = await axios.get('/3d/get_closed_threed_numbers',
        {
          headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          }
        );
        this.text = res.data.data.length > 0
          ? `${
            this.$store.state.language === 'en'
              ? 'Closed number(s)'
              : 'ပိတ်ထားသော နံပါတ်များ'
          } : ${res.data.data.toString()}`
          : null;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }

    this.fetchClosedNumbers();
    this.fetchThreedNumbers();
    this.checkCurrentVouchers();
    this.threeData();
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    if (this.$auth.check()) {
      this.$store.commit(
        'setAmount',
        parseInt(this.$store.state.authUser.amount, 10),
      );
    }
  },
};
</script>
<style scoped>
.btn{
  background: #083D0B !important;
}
.number-reverse {
  background: #000000;
  color: white;
  border-radius: 5px;
  /* font-size: 20px; */
  font-weight: bold;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 2px solid transparent;
}

.number-reverse.active {
  background: rgb(125, 225, 135);
  border: 2px solid black;
  color: black;
}

.number-paginate{
    border:2px solid #000000;
    border-radius: 10px;
    /* padding: 10px; */
    color: #000000;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
}
.number-paginate.active{
    background: #000000;
    color: white;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
.input-group {
    border: 1px solid #cccccc;
}
.notice-bar {
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #172b4c;
    background: rgba(255, 242, 0, 0.7);
}
@media (max-width: 768px) {
    .boxes {
        margin-top: 6px;
        margin-bottom: 7px;
    }
    .main-wrapper {
        padding: 0px !important;
    }
}
</style>
